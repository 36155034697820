export enum TLng {
  ru = "ru",
  es = "es",
}

// export type TLangToken = "home__side_interesting_read__header" | "home__where_bet__header"
// export type TLang = Record<TLangToken, string>
export type TLang = {
  header__search_bar__placeholder: string
  category__brands: string
  category__bonuses: string
  category__articles: string
  category__popular_articles: string
  category__tips: string
  category__news: string
  button__get: string
  widget__option_hide: string
  widget__option_copy_link: string
  widget__snack_message__copied: string
  widget__snack_message__hidden: string
  home__side_interesting_read__header: string
  home__where_bet__header: string
  rating_item__brand_page: string
  rating_item__brand_link: string
  home__feed__header: string
  home__feed_select__aria_label: string
  home__feed_select__all: string
  button__more_details: string
  academy_widget__header: string
  home__side_learn_more__button: string
  button__all_brands: string
  button__show_more: string
  footer__tips__header: string
  footer__best_articles__header: string
  footer__more__button: string
  home__feed__read_the_tip: string
  tip__finished: string
  home__feed_question__show_more_options: string
  page_loader__loading: string
  brand__go_to_site__button: string
  brand__about__header: string
  brand__about_table__trade_name: string
  brand__about_table__url: string
  brand__about_table__support_phone: string
  brand__about_table__support_email: string
  brand__about_table__founded: string
  brand__about_table__closed: string
  brand__about_table__founders: string
  brand__about_table__ceo: string
  brand__about_table__legal_name: string
  brand__about_table__headquarters: string
  brand__about_table__psrn: string
  brand__about_table__itn: string
  brand__about_table__iec: string
  brand__about_table__license: string
  brand__payments__header: string
  brand__payments__cards: string
  brand__payments__deposit_amount: string
  brand__payments__deposit_time: string
  brand__payments__deposit_comission: string
  brand__payments__cashout_amount: string
  brand__payments__cashout_time: string
  brand__payments__cashout_comission: string
  brand__pros: string
  brand__cons: string
  category__reviews: string
  all_reviews__button: string
  brand__articles_on_the_topic: string
  brand__bonuses_from_brand: string
  brand__all_bonuses__button: string
  ratings_page__img_alt: string
  all_reviews_page__header: string
  all_reviews_page__meta_description: string
  all_reviews_page__lead: string
  reviews__no_reviews__card: string
  all_reviews_page__img_alt: string
  reviews__leave_a_review__button: string
  all_reviews_page__all_reviews__header: string
  review_card__helpful: string
  reply_review__snack_message__you_replied: string
  reply_review__snack_message__error: string
  reply_review__your_brand_answer: string
  review_card__brand_answer__answer: string
  review_page__header: string
  review_page__img_alt: string
  review_page__read_overview__button: string
  reviews__no_reviews__card_alt: string
  review_page__meta_title: string
  review_page__meta_description_before_brand: string
  review_page__meta_description_after_brand: string
  review_page__all_reviews_about_the_brand: string
  review_page__filter_option__in_order: string
  review_page__filter_option__by_rating: string
  review_page__rating_stars__5_super: string
  review_page__rating_stars__4_great: string
  review_page__rating_stars__3_fine: string
  review_page__rating_stars__2_not_good: string
  review_page__rating_stars__1_bad: string
  bonus_card__finished__button: string
  bonus_card__get_bonus__button: string
  bonus_list_page__hot_bonuses__title: string
  bonus_list_page__hot_bonuses__description: string
  article_page__updated: string
  article_page__article_headings__title: string
  bonus_page__finished__button: string
  bonus_page__get_bonus__button: string
  bonus_page__your_promocode__title: string
  article_page__reactions__title: string
  article_page__socials__title: string
  article_page__comments__question: string
  bonus_page__comments__write_field: string
  follow_author__snack_message__unsubscribe: string
  follow_author__snack_message__subscribe: string
  follow_author__snack_message__error: string
  user_page__you_are_follower__button: string
  user_page__subscribe__button: string
  bonus_page__articles_more__bonuses: string
  bonus_page__articles_more__bonuses_from: string
  comment_form__modal__header: string
  comment_form__modal__message: string
  comment_form__modal__enter__button: string
  comment_form__your_comment: string
  comment_form__close_answer_form__button: string
  comment_form__send__button: string
  comment_form__answer__button: string
  comment_form__delete__button: string
  delete_comment__modal__header: string
  delete_comment__modal__message: string
  delete_comment__modal__yes_button: string
  delete_comment__modal__no_button: string
  delete_comment__snack_message__deleted: string
  delete_comment__snack_message__error: string
  send_comment__snack_message__published: string
  send_comment__snack_message__error: string
  casino__bonuses_from_casino: string
  tip_list_page__h1: string
  tip_list_page__meta_title: string
  tip_list_page__no_tips: string
  header_nav__tip_card__tip: string
  tip_mini_card__more__button: string
  tip_mini_card__finished: string
  tip_page__hero_card__finished: string
  tip_page__hero_card__continues: string
  tip_page__hero_card__vs: string
  tip_page__hero_card__get_bonus__button: string
  tip_page__hero_card__before_the_beginning: string
  tip_page__hero_card__before__days: string
  tip_page__hero_card__before__hours: string
  tip_page__hero_card__before__minutes: string
  tip_page__hero_card__bet__button: string
  tip_page__tip_forecast__coefficient: string
  tip_page__tip_forecast__bet__button: string
  tip_page__comments__write_field: string
  tip_page__articles_more__tips: string
  tip_page__articles_more__all_tips__button: string
  blog_page__comments__write_field: string
  blog_page__articles_more__blogs: string
  news_page__comments__write_field: string
  news_page__articles_more__news: string
  academy_page__test_title: string
  academy_page__test_description: string
  academy_page__test_button: string
  academy_page__tab__important: string
  academy_page__tab__sports: string
  academy_page__tab__bets: string
  academy_page__tab__strategies: string
  account__connect_bk__button: string
  account__connected: string
  account__header__settings: string
  upload_avatar__snack_message__error: string
  account__mini_menu__profile: string
  account__mini_menu__settings: string
  account__menu__settings: string
  account__mobile_menu__settings: string
  account__heading__notifications_title: string
  account__heading__notifications_description: string
  account__switch__activity_in_comments: string
  account__switch__bonuses: string
  notifications_switch__snack_message__disabled: string
  notifications_switch__snack_message__enabled: string
  update_account__snack_message__saved: string
  update_account__snack_message__error: string
  account__heading__data_title: string
  account__heading__data_description: string
  account__photo: string
  account__info: string
  account__info__name: string
  account__info__name__placeholder: string
  account__info__surname: string
  account__info__surname__placeholder: string
  account__info__nickname: string
  account__info__nickname__rules: string
  account__info__about_me: string
  account__socials: string
  account__socials__vkontakte: string
  account__socials__facebook: string
  account__socials__twitter: string
  account__socials__telegram: string
  account__socials__instagram: string
  account__socials__site: string
  account__socials__link__placeholder: string
  account__info__button: string
  breadcrumbs__home_page: string
  breadcrumbs__bookmakers_page: string
  bonus_bk__snack_message__copied: string
  bonus_list_page__no_bonuses__card: string
  breadcrumbs__casino: string
  comments: string
  comments__reply_to_comment: string
  comments__form__placeholder: string
  question_answer__is_correct: string
  question_answer__is_wrong: string
  question_answer__show_result__button: string
  question_answer__show_next_question__button: string
  footer__region_toggle__label: string
  footer__landing__before_adult_policy: string
  footer__adult_policy__text: string
  glossary_page__comments__write_field: string
  glossary_page__articles_more__blogs: string
  glossary_list_page__text: string
  header_user_menu__profile: string
  header_user_menu__settings: string
  header_user_menu__exit: string
  header_nav__text_link__more: string
  header_nav__text_link__bookmakers: string
  header_nav__text_link__academy: string
  header_nav__text_link__bonuses: string
  header_nav__text_link__tips: string
  header_nav__academy_test__title: string
  header_nav__academy_test__button: string
  http_error_page__meta: string
  http_error_page__text: string
  http_error_page__to_home_page__button: string
  notifications_header__text: string
  notifications_menu__user_notifications: string
  notifications_menu__user_notifications__no_notifications: string
  notification__commented_on_the_article: string
  notification__replied_to_your_comment: string
  quiz_result__correct_answers: string
  quiz_result__from: string
  quiz_result__go_again__button: string
  quiz_result__share_result__text: string
  quiz_result__get_bonus__button: string
  breadcrumbs__reviews: string
  send_review__snack_mesage__published: string
  send_review__snack_mesage__error: string
  review_dialog__select__choose_bookmaker: string
  review_dialog__your_mark: string
  review_dialog__title__placeholder: string
  review_dialog__text__placeholder: string
  review_dialog__checkbox_start_phrase__tell_bookmaker: string
  review_dialog__checkbox_end_phrase__about_your_review: string
  review_dialog__publish__button: string
  header__search_bar__not_found: string
  team_page__table__about_team: string
  team_page__table__country: string
  team_page__table__founded_at: string
  team_page__table__coach: string
  team_page__table__stadium: string
  team_page__table__site_link: string
  team_page__table__socials: string
  team_page__title: string
  team_page__description__start: string
  team_page__description__end: string
  team_page__view_squad: string
  team_page__history: string
  team_page__next_match_tip: string
  team_page__articles_more__news: string
  team_squad_page__title: string
  team_squad_page__description: string
  breadcrumbs__team_squad: string
  team_squad_page__view_team_page: string
  team_squad_page__current_squad: string
  team_squad_page__year: string
  team_squad_page__table__player: string
  team_squad_page__table__role: string
  team_squad_page__table__nationality: string
  team_squad_page__table__height: string
  team_squad_page__table__weight: string
  team_squad_page__table__age: string
  tip_list_page__filter__all_sports: string
  tip_list_page__filter__all_tournaments: string
  breadcrumbs__tips: string
  user_article_list__no_articles: string
  user_article_list__articles: string
  user_article_list__no_comments: string
  user_article_list__comments: string
  user_article_list__no_tips: string
  user_article_list__tips: string
  widget_home_tips__go: string
  brand__payments__from: string
  brand__payments__to: string
}

export type TLangToken = keyof TLang

export type TLangs = {
  ru: TLang
  es: TLang
}

enum EDictionary {
  day_short,
  hour_short,
  minute_short,
  right_now,
  yesterday,
  ago,
  reading_time,
  wrong_login_password,
  all_bookmakers,
  all_categories,
  bonuses_h1,
  bonuses_meta_title_start,
  bonuses_meta_title_end,
  bonuses_meta_description_start,
  bonuses_meta_description_end,
  bk_review_all_bks,
  news_feed_all_categories,
  user_news_meta_title,
  user_page_meta_title,
  user_news_meta_description,
  user_page_meta_description_start,
  user_page_meta_description_end,
  user_tips_meta_title,
  user_tips_meta_description,
  user_comments_meta_title,
  user_comments_meta_description,
  bonus_exclusive_pseudo,
  brand_rating_pseudo,
  brand_widget_to_site_pseudo,
  brand_widget_go_to_site_pseudo,
  comment_author,
  tip_hero_days,
  tip_hero_hours,
  tip_hero_minutes,
  rss_title,
  rss_description,
}

enum EWordsForDeclension {
  day,
  hour,
  minute,
  minuteForTournament,
  vote,
  freebet,
  bonus,
  years_old,
  follower,
  participant,
}

type TWordForDictionary = {
  ru: string
  es: string
}

type TDictionary = Record<keyof typeof EDictionary, TWordForDictionary>

type TWordsForDeclension = {
  ru: [string, string, string]
  es: [string, string, string]
}

type TDictionaryForDeclension = Record<
  keyof typeof EWordsForDeclension,
  TWordsForDeclension
>

export const dictionary: TDictionary = {
  day_short: { ru: " д.", es: " d." },
  hour_short: { ru: " ч.", es: " h." },
  minute_short: { ru: " мин", es: " min" },
  right_now: { ru: "Только что", es: "Hace un momento" },
  yesterday: { ru: "Вчера", es: "Ayer" },
  ago: { ru: " назад", es: " atrás" },
  reading_time: { ru: " чтения", es: " lecturas" },
  wrong_login_password: {
    ru: "Неверный логин/пароль",
    es: "Logín/contraseña no válida",
  },
  all_bookmakers: {
    ru: "Все букмекеры",
    es: "Todos los corredores de apuestas",
  },
  all_categories: { ru: "Все категории", es: "Todas las categorías" },
  bonuses_h1: {
    ru: "Бонусы букмекера",
    es: "Bonos de corredor de apuestas",
  },
  bonuses_meta_title_start: {
    ru: "Акции и бонусы",
    es: "Promociones y bonificaciones",
  },
  bonuses_meta_title_end: {
    ru: "список всех предложений",
    es: "lista de todas las ofertas",
  },
  bonuses_meta_description_start: {
    ru: "Все виды бонусов",
    es: "Todos los tipos de bonificaciones",
  },
  bonuses_meta_description_end: {
    ru: "на одной странице: приветственные, за депозит, программа лояльности и другие",
    es: "en una sola página: bienvenida, para depósito, programa de fidelización y otros",
  },
  bk_review_all_bks: {
    ru: "Все букмекеры",
    es: "Todos los corredores de apuestas",
  },
  news_feed_all_categories: { ru: "все категории", es: "todas las categorías" },
  user_news_meta_title: {
    ru: "новости пользователя на betnbet",
    es: "noticias del usuario en betnbet",
  },
  user_page_meta_title: {
    ru: "страница на betnbet",
    es: "página en betnbet",
  },
  user_news_meta_description: {
    ru: "Новости, написанные пользователем",
    es: "Noticias presentadas por el usuario",
  },
  user_page_meta_description_start: {
    ru: "Страница пользователя",
    es: "Página del usuario",
  },
  user_page_meta_description_end: {
    ru: "информация, комментарии и прочее",
    es: "información, comentarios, etc.",
  },
  user_tips_meta_title: {
    ru: "прогнозы пользователя на betnbet",
    es: "pronósticos del usuario en betnbet",
  },
  user_tips_meta_description: {
    ru: "Прогнозы, написанные пользователем",
    es: "Pronósticos presentadas por el usuario",
  },
  user_comments_meta_title: {
    ru: "комментарии пользователя на betnbet",
    es: "comentarios del usuario en betnbet",
  },
  user_comments_meta_description: {
    ru: "Комментарии, написанные пользователем",
    es: "Comentarios presentados por el usuario",
  },
  bonus_exclusive_pseudo: {
    ru: "эксклюзив betnbet",
    es: "exclusividad de betnbet",
  },
  brand_rating_pseudo: { ru: "ОЦЕНКА BETNBET", es: "EVALUACIÓN DE BETNBET" },
  brand_widget_to_site_pseudo: { ru: "На сайт", es: "Al sitio" },
  brand_widget_go_to_site_pseudo: {
    ru: "Перейти на сайт",
    es: "Ir al sitio Web",
  },
  comment_author: { ru: "АВТОР", es: "AUTOR" },
  tip_hero_days: { ru: "дней", es: "días" },
  tip_hero_hours: { ru: "час.", es: "horas" },
  tip_hero_minutes: { ru: "мин.", es: "min." },
  rss_title: { ru: "Новости betnbet", es: "Noticias de betnbet" },
  rss_description: {
    ru: "Ежедневные прогнозы и новости",
    es: "Pronósticos y noticias diarios",
  },
}

export const dictionaryForDeclension: TDictionaryForDeclension = {
  day: { ru: [" день", " дня", " дней"], es: [" día", " días", " días"] }, //es: [" día", " días", " días"]
  minute: {
    ru: [" минута", " минуты", " минут"],
    es: ["minuto", " minutos", " minutos"],
  },
  minuteForTournament: {
    ru: [" минуту", " минуты", " минут"],
    es: ["minuto", " minutos", " minutos"],
  },
  hour: {
    ru: [" час", " часа", " часов"],
    es: [" hora", " horas", " horas"],
  },
  vote: {
    ru: [" голос", " голоса", " голосов"],
    es: [" voto", " votos", " votos"],
  },
  freebet: {
    ru: [" фрибет", " фрибета", " фрибетов"],
    es: [" apuesta gratis", " apuestas gratis", " apuestas gratis"],
  },
  bonus: {
    ru: [" бонус", " бонуса", " бонусов"],
    es: [" bono", " bonos", " bonos"],
  },
  years_old: {
    ru: [" года", " лет", " лет"],
    es: ["año", " años", " años"],
  },
  follower: {
    ru: [" подписчик", " подписчика", " подписчиков"],
    es: [" suscriptor", " suscriptores", " suscriptores"],
  },
  participant: {
    ru: [" участник", " участника", " участников"],
    es: [" participante", " participantes", " participantes"],
  },
}
