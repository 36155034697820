import { proxyApi } from "@betnbet/front-sdk/dist/auth"
import { errorLog } from "@betnbet/front-sdk/dist/util/error"
import {
  createGenerateClassName,
  jssPreset,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles"
import { createBrowserHistory, Location } from "history"
import { create } from "jss"
import React, { FC, useEffect, useState } from "react"
import { hydrate, render } from "react-dom"
import { Router } from "react-router"
import { createStory } from "story"
import { getEnv } from "./App/AppEnv"
import { DepsContext, TDeps, TStoryDeps } from "./App/di"
import { TCsRecord, TStyleGroups } from "./App/styles"
import { ErrorBoundary } from "./common/error-boundary"
import { api } from "./graphql/api-browser"
import { TApi } from "./graphql/graphql-sdk"
import { TMe, TSsrState } from "./payload"
import {
  createBranchItemMapper,
  DataRoutes,
  loadBranch,
  TAppBranchItem,
} from "./routes/route"
import { TAppStory } from "./routes/route-types"
import { routes } from "./routes/routes"
import { TWidgetConfig, useStore } from "./store/store"
import { theme } from "./theme"

const authRetryApi = proxyApi<TApi>(api)

const cs = new Proxy({} as TStyleGroups, {
  get: function (_target, componentName: keyof TStyleGroups, _receiver) {
    if (_target[componentName]) return _target[componentName]
    const p = new Proxy({} as TCsRecord<string>, {
      get: function (_target, name: string, _receiver) {
        return componentName + "_" + name
      },
    })
    _target[componentName] = p as any
    return p
  },
})

const init = async () => {
  const history = createBrowserHistory()
  const env = getEnv()
  const user = env.AUTH ? await authRetryApi.me() : undefined

  const story: TAppStory = createStory({
    branchItemsMapper: (branchItem, abortController) =>
      createBranchItemMapper(story, deps)(
        branchItem as TAppBranchItem,
        abortController
      ),
    data: window.ssr_state.data,
    statusCode: window.ssr_state.status,
    onLoadError: (err) => {
      console.log("onLoadError", err)
    },
  })

  const deps: TStoryDeps = {
    theme,
    env,
    api: authRetryApi,
    history,
    story,
    cs,
    region: window.ssr_state.region,
    region_list: window.ssr_state.region_list,
    user_country_iso: window.ssr_state.user_country_iso,
    lang: window.ssr_state.lang,
    lng: window.ssr_state.lng,
  }

  const { widget } = await loadBranch(story, routes, history.location)

  const el = document.getElementById("app")
  if (!el) throw new Error("no el")

  const generateClassName = createGenerateClassName()
  const jss = create({
    ...jssPreset(),
    insertionPoint: document.getElementById("jss-append")!,
  })

  const renderer = el.childNodes.length === 0 ? render : hydrate
  renderer(
    <StylesProvider generateClassName={generateClassName} jss={jss}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary log={errorLog}>
          <Browser
            deps={deps}
            auth0_domain={env.AUTH0_DOMAIN}
            auth0_client_id={env.AUTH0_CLIENT_ID}
            user={user}
            widget={widget}
          />
        </ErrorBoundary>
      </ThemeProvider>
    </StylesProvider>,
    el
  )

  const jssEl = document.getElementById("jss")
  jssEl?.parentNode?.removeChild(jssEl)
  history.listen(() => window.ga && window.ga("send", "pageview"))
}

init()

declare global {
  interface Window {
    ssr_state: TSsrState
  }
}

type TAppRootBrowserProps = {
  deps: TStoryDeps
  widget: TWidgetConfig | undefined
  user: TMe | undefined
  auth0_domain: string
  auth0_client_id: string
}

const Browser: FC<TAppRootBrowserProps> = (props) => {
  const { deps, widget, user, auth0_domain, auth0_client_id } = props
  const [, set_render_location] = useState(deps.story.state.location)
  const [, up] = useState([])

  const depsWithStore: TDeps = {
    ...deps,
    store: useStore({
      widget: window.ssr_state.widget || widget,
      user,
      auth0_domain,
      auth0_client_id,
    }),
  }

  useEffect(() => {
    deps.history.listen(async ({ location, action }) => {
      deps.story.abortLoading()
      up([])
      const { widget } = await loadBranch(
        deps.story,
        routes,
        location,
        action === "PUSH"
      )
      widget && depsWithStore.store.widget.setWidgetConfig(widget)
      set_render_location(location)
    })
  }, [])

  return (
    <DepsContext.Provider value={depsWithStore}>
      <Router
        navigator={deps.history}
        location={deps.story.state.location as Location}
      >
        <DataRoutes routes={routes} story={deps.story} />
      </Router>
    </DepsContext.Provider>
  )
}
